<template>
    <div class="home">
        <div class="home-title">
            <el-card shadow="always">
                <el-row>
                    
                    <el-col :span="6" style="text-align: left;" class="hidden-md-and-down" >
                        <div class="greetings">您好,{{username}}</div>
                        <div class="weather">今天是：{{curdate}}</div>
                    </el-col>
                    
                </el-row>
            </el-card>
        </div>
          <div class="home-card m120" v-if="ismerchant != 1">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-card>
                  <div class="card-info">
                      <div class="card-info-title"><i class="el-icon-document"></i> 总订单数</div>
                      <div class="card-info-no">{{info.total_order_count}}</div>
                      <div class="card-info-date">
                        <div class="date">
                          <span>昨日：{{info.yesterday_order_count}}</span>
                        </div>
                        <div class="date">
                          <span>今日：{{info.today_order_count}}</span>
                        </div>
                      </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="6">
                <el-card>
                  <div class="card-info">
                    <div class="card-info-title"><i class="el-icon-coin"></i> 总收入</div>
                      <div class="card-info-no">{{info.total_income}}</div>
                      <div class="card-info-date">
                        <div class="date">
                          <span>昨日：{{info.yesterday_income}}</span>
                        </div>
                        <div class="date">
                          <span>今日：{{info.today_income}}</span>
                        </div>
                      </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="6">
                <el-card>
                  <div class="card-info">
                    <div class="card-info-title"><i class="el-icon-box"></i> 总发货</div>
                      <div class="card-info-no">{{info.total_deliver}}</div>
                      <div class="card-info-date">
                        <div class="date">
                          <span>昨日：{{info.yesterday_deliver}}</span>
                        </div>
                        <div class="date">
                          <span>今日：{{info.today_deliver}}</span>
                        </div>
                      </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="6">
                <el-card>
                  <div class="card-info">
                    <div class="card-info-title"><i class="el-icon-files"></i> 上架套餐</div>
                      <div class="card-info-no">{{info.planno}}</div>
                      <div class="card-info-date">
                        <div class="date">
                          <span>昨日：{{info.yesterday_up}}</span>
                        </div>
                        <div class="date">
                          <span>今日：{{info.today_up}}</span>
                        </div>
                      </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="home-card" v-if="ismerchant != 1">
            <el-row :gutter="20">
                  <el-col :span="4">
                    <el-card shadow="always" class="info-block">
                      <div class="contentblock">
                        <div style="font-size:16px;">待审批</div>
                        <div style="font-size:16px;">{{info.order_1}}</div>
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="4">
                    <el-card shadow="always" class="info-block">
                      <div class="contentblock">
                        <div style="font-size:16px;">待支付</div>
                        <div style="font-size:16px;">{{info.order_2}}</div>
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="4">
                    <el-card shadow="always" class="info-block">
                      <div class="contentblock">
                        <div style="font-size:16px;">待发货</div>
                        <div style="font-size:16px;">{{info.order_3}}</div>
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="4">
                    <el-card shadow="always" class="info-block">
                      <div class="contentblock">
                        <div style="font-size:16px;">待收货</div>
                        <div style="font-size:16px;">{{info.order_4}}</div>
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="4">
                    <el-card shadow="always" class="info-block">
                      <div class="contentblock">
                        <div style="font-size:16px;">待归还</div>
                        <div style="font-size:16px;">{{info.order_5}}</div>
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="4">
                    <el-card shadow="always" class="info-block">
                      <div class="contentblock">
                        <div style="font-size:16px;">归还中</div>
                        <div style="font-size:16px;">{{info.order_6}}</div>
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="4">
                    <el-card shadow="always" class="info-block">
                      <div class="contentblock">
                        <div style="font-size:16px;">定损中</div>
                        <div style="font-size:16px;">{{info.order_7}}</div>
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="4">
                    <el-card shadow="always" class="info-block">
                      <div class="contentblock">
                        <div style="font-size:16px;">逾期中</div>
                        <div style="font-size:16px;">{{info.order_8}}</div>
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="4">
                    <el-card shadow="always" class="info-block">
                      <div class="contentblock">
                        <div style="font-size:16px;">强制买断中</div>
                        <div style="font-size:16px;">{{info.order_9}}</div>
                      </div>
                    </el-card>
                  </el-col>
            </el-row>
          </div>
          <el-row :gutter="20" v-if="ismerchant == 1" style="margin-top:20px;">
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
              <el-card shadow="never" class="box-card" style="background:#00C9B7;color:#fff">
                <div class="settleblock">
                  <p style="font-size:20px;margin:10px 0 10px 0"><strong>结算账户</strong></p>
                  <p style="font-size:30px;margin:10px 0 10px 0"><strong>¥ {{settle?settle:0}}</strong></p>
                  <div style="margin-left:auto;">
                    <el-button size="small" style="background:transparent;color:#fff;" @click="Settle">明细</el-button>
                    <el-button size="small" style="background:transparent;color:#fff" @click="Cashout">提现</el-button>
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
              <el-card shadow="never" class="box-card" style="background:#FFCC71;color:#fff">
                <div class="settleblock">
                  <p style="font-size:20px;margin:10px 0 10px 0"><strong>提现总额</strong></p>
                  <p style="font-size:30px;margin:10px 0 10px 0"><strong>¥ {{cashout?cashout:0}}</strong></p>
                  <div style="margin-left:auto;">
                    <el-button size="small" style="background:transparent;color:#fff;" @click="Cashout">明细</el-button>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
    </div>
        
    
</template>

<script>
    
    //import {VueEcharts} from 'vue3-echarts'
    // import {revenueAnalysis,overview} from '@/api/serviceApi'
    import constant from "@/constant"
    import Cookies from 'js-cookie'
    export default {
        
        data(){
            return {
                username:'',
                expired: 0,
                abouttoexpired: 0,
                on: 0,
                info:[],
                ismerchant:'',
                mid:'',
                settle:'',
                cashout:'',
            }
        },

        methods:{
          Settle(){
            this.$router.push({path:'/settle'});
          },
          Cashout(){
            this.$router.push({path:'/cashout'});
          },
           GetStatisticInfo(){
              this.axios.get(constant.statisticinfo,{
                headers:{
                  "Content-Type": "application/json",
                }
              }).then((response)=>{ 
                console.log(response.data);
                this.info = response.data;
              })
           },
           GetSettleNCashout(){
             this.axios.get(constant.sncinfo,{
               headers:{
                 "content-type":"application/json"
               },
               params:{
                 mid:this.mid,
               }
             }).then((response)=>{
               console.log(response.data);
               this.settle = response.data.settle;
               this.cashout = response.data.cashout;
             });
           }
        },

        created:function(){
            this.ismerchant = Cookies.get("IsMerchant");
            this.mid = Cookies.get("mid");
            this.GetStatisticInfo();
            this.GetSettleNCashout();
            var dd = new Date();
            var year = dd.getFullYear();
            var month =
            dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
            var day = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
            this.curdate = year + "-" + month + "-" + day;

            const username = Cookies.get("name");
            this.username = username;
            console.log(Cookies.get("patharr"));
        }

    }
</script>
<style scoped>
    .settleblock {
      padding: 0 20px 20px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .contentblock{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .card-info{
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .card-info-title{
      font-weight: 200;
      font-size: 20px;
    }
    .card-info-no{
      padding:5px;
      font-size: 36px;
      font-weight: 300;
    }
    .card-info-date{
      width:100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    .info-block{
      margin-top:20px;
      
    }
    .home .home-title .title-msg {
                font-size: 20px;
                padding-bottom: 2rem;
    }
    .home .home-title .greetings {
                font-size: 18px;
                line-height: 50px;
                letter-spacing: .1em;
    }
    .home .home-title .weather {
                font-size: 14px;
                color: #999;
                line-height: 24px;
                letter-spacing: .1em;
    }
    .home .home-title .item {
                color: #808695;
                text-align: center;
                padding: 0.5em;
    }
    .home .home-title .item-text {
                font-weight: 700;
                font-size: 20px;
                color: #000000;
    }
    .home .home-card .card-text {
                font-size: 12px;
                font-weight: 400;
                color: #494b52;
                letter-spacing: .1em;
                padding-top: 0.4em;
    }
    .home .home-chart .satisfaction {
                padding-top: 0.2em;
                text-align: center;
    }
    .home .home-chart .satisfaction-number {
                font-weight: 700;
                font-size: 30px;
                color: #515a6e;
                line-height: 120px;
                text-align: center;
    }
    .home .home-chart .satisfaction-image {
                width: 60px;
                height: 60px;
                text-align: center;
    }
    .home .home-chart .satisfaction-msg {
                line-height: 35px;
                color: #808695;
                font-size: 14px;
                text-align: center;
    }
    .home .home-chart .satisfaction-ratio {
                line-height: 120px;
                color: #36c17b;
                font-size: 18px;
                text-align: center;
    }
    .home .home-chart .sale-item{
      border-radius: 0.2em;
                line-height: 3.2em;
                text-align: center;
                margin: 1em 0;
    }
    .home .home-chart .sale-item .item-image {
                    top: 0.6em;
                }
    .home .home-chart .sale-item .item-num {
                    height: 2.4em;
                    line-height: 2.4em;
                    color: white;
                    font-weight: bold;
                    font-size: 20px;
                    text-align: right;
                    padding-right: 1em;
                }
    .home {
        padding-bottom: 4em;
    }

    .content {
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        
    }
/**
 内边距
 */
.p4em{
  padding-right: 0.4em;
}

/**
 外边距
 */
.m120{
  margin-top: 1.2em;
}
.mb120{
  margin-bottom: 1.2em;
}

/**
 颜色
 */
.c-red{
  color: red;
}
.c-coral{
  color: coral;
}
.c-brown{
  color: brown;
}
.c-cyan{
  color: cyan;
}
.c-forestgreen{
  color: forestgreen;
  font-weight: bold;
}
.c-blue {
    color: #1C86EE
}
/**
 数字效果
 */
.num-effect{
  color: #494b52;
  font-size: 1.5em;
  font-weight: bold;
}

.f-weight{
  font-weight: bold;
}

.div-right{
  float: right;
}

.icon{
  width: 30px;
  height: 30px;
  right: 0.2em;
}
</style>
